// eslint-disable-next-line import/no-unresolved
import { Provider } from "react-redux";
import store from "./store/store";
// routes
import Router from "./routes";
// theme
import { Toaster } from "react-hot-toast";
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
// style
import "./assets/styles/index.css";
import useUTMParameters from "./hooks/useUTMParams";

// ----------------------------------------------------------------------

function App() {
  useUTMParameters();
  return (
    <Provider store={store}>
      <ThemeProvider>
        <ScrollToTop />
        <Toaster position="top-right" reverseOrder={false} />
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
