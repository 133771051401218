export const appendUTMParameters = (baseUrl) => {
  // List of UTM parameters to check
  const utmParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  // Create URL object
  const url = new URL(baseUrl);

  // Add existing UTM parameters from localStorage
  utmParams.forEach((param) => {
    const value = localStorage.getItem(param);
    if (value) {
      url.searchParams.append(param, value);
    }
  });

  return url.toString();
};
