// export const openInNewTab = (url) => {
//   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//   if (newWindow) newWindow.opener = null;
// };

import { appendUTMParameters } from "../utils/appendUTMParameters";

export const openInNewTab = (url) => {
  const urlWithUTMs = appendUTMParameters(url);
  const newWindow = window.open(urlWithUTMs, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
