import { useEffect } from "react";

const useUTMParameters = () => {
  const getURLParameter = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

  useEffect(() => {
    const updateLocalStorage = () => {
      const utmSource = getURLParameter("utm_source");
      const utmCampaign = getURLParameter("utm_campaign");
      const utmMedium = getURLParameter("utm_medium");
      if (utmSource || utmCampaign || utmMedium) {
        localStorage.setItem("utm_source", utmSource || "");
        localStorage.setItem("utm_campaign", utmCampaign || "");
        localStorage.setItem("utm_medium", utmMedium || "");
      }
    };

    updateLocalStorage();
  }, []);
};

export default useUTMParameters;
