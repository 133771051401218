import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { openInNewTab } from "../../../helpers/openInNewTab";

const CustomColoredButton = styled(Button)(({ theme }) => ({
  minWidth: "150px",
  fontSize: "18px",
  borderRadius: "15px",
  fontWeight: "600",
  lineHeight: "30px",
  padding: "14px 24px",
  transition: "color .3s ease,background-color .3s ease,border .3s ease",
  background: "black",

  "&:hover": {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default function B2B() {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <img
              src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Nursing+cert+badge+seal.svg"
              alt="cnmap-img"
            />
          </Grid>
          <Grid item md={8}>
            <Typography sx={{ fontWeight: 700 }} variant="h4">
              Become a Certified Nurse Medical Affairs Professional
            </Typography>
            <Typography sx={{ mt: 1 }}>
              The Certified Nurse Medical Affairs Program (CNMAP™) is a
              self-paced, online, certification program that provides nurses and
              advanced degree nurses the opportunity to educate themselves in
              the areas of medical affairs and life sciences, and therefore,
              creating a new avenue to career development.
            </Typography>
            <Box sx={{ mt: 3 }}>
              <CustomColoredButton
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  openInNewTab(
                    "https://www.acmamarketplace.org/certification/cnmap"
                  );
                }}
              >
                Enroll Today
              </CustomColoredButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
